import React from "react";
import { rangeDate } from "~src/helpers/dateTime";
import { useAppSelector } from "~src/store";
import { getCountryNameState, travelInfoState } from "../redux";

const TravelInfo = () => {
  const state = useAppSelector(travelInfoState);
  const countryName = useAppSelector(getCountryNameState);

  return (
    <div className="">
      <div className="flex flex-row">
        <div className="text-gray-500">
          พบ <span className="text-gray-700 font-bold italic">{state.count}</span> แผนประกันสำหรับ
        </div>
        <div className="flex flex-col ml-4">
          <div>
            <b>เดินทางไป : </b> {countryName}
          </div>
          <div className="flex flex-row mt-2">
            <div>
              <b>จำนวน : </b>{state.travelers} คน
            </div>
            <div className="ml-4">
              <b>ระยะเวลาเดินทาง : </b>{rangeDate(state.startDate, state.endDate)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelInfo;
