import _ from "lodash";
import React, { useState, useEffect } from "react";
import { match as Match } from "react-router-dom";

import Badges from "~src/components/badges";
import { ButtonRadius } from "~src/components/form";
import OrderDetail from "~src/components/orderDetail";
import ProductTypeIcon from "~src/components/ProductTypeIcon";
import ContinueOrderButton from "~src/components/form/button/ContinueOrderButton";

import statusYml from "~src/status.yml";
import dataYml from "~src/data.yaml";
import { defaultStaffOrdersAPIFactory } from "~src/api/staff/Orders";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { reformatDateEN } from "~src/helpers/date";
import { getInsuredByCategory, dateFormat } from "~src/helpers/helpers";
import { renderYaml } from "~src/helpers/renderYaml";
import useOrder from "~src/hooks/useOrder";

import "./Details.scss";

export interface StaffOrderDetailsPageProps {
  match: Match<any>;
}

export default function StaffOrderDetailsPage(props: StaffOrderDetailsPageProps) {
  // TODO: Revise this later
  return (
    <PageContextProvider>
      <StaffOrderDetailsPageInner {...props} />
    </PageContextProvider>
  );
}
type StaffOrderDetailsPageInnerProps = StaffOrderDetailsPageProps;

var StaffOrderDetailsPageInner = ({ match }) => {
  const { onError, setAlert } = usePageContext();
  const { isLoading, order, checkedAddon, installmentInfo, reload } = useOrder(match.params.id, {
    suffix: "info",
    apiFactory: defaultStaffOrdersAPIFactory,
    onError,
  });

  const getShortInformation = () => {
    switch (order?.quotation?.product_type) {
      case "travel":
        return [
          "ข้อมูลการเดินทาง",
          `เดินทาง ${order.quotation?.short_desc_criteria}`,
          `ไป ${order.quotation?.additional_information?.destination_name}`
        ];
      default:
        return [
          "ข้อมูลรถยนต์ / รายละเอียดเบื้องต้น",
          `${order?.quotation?.criteria?.car_brand} ${order?.quotation?.criteria?.car_model}`,
        ];
    }
  };

  const shortInformation = getShortInformation();
  const isCreditTerm = order?.payments?.[0]?.pay_method == "credit_term";

  const handleUploadAttachment = () => {
    window.location.reload();
  }

  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      {isLoading && <div className="agent-order-detail-loading">กำลังโหลด...</div>}
      {!isLoading && order && (
        <div className="agent-order-detail">
          <div className="card sticky">
            <div className="agent-order-detail-header">
              <div>
                <small>รายการคำสั่งซื้อ</small>
                <div className="flex align-items-center">
                  <h6 className="mr-2">
                    <ProductTypeIcon type={order.quotation?.product_type} />
                    {order.number}{" "}
                  </h6>
                  {!isCreditTerm &&
                    <Badges status={`order-${order.status}`}>
                      {statusYml.status.orders[order.status]}
                    </Badges>
                  }
                  {order.status === "open" && (
                    <small>วันหมดอายุ: {reformatDateEN(order.expired_at)}</small>
                  )}
                </div>
              </div>
              <div>
                {order.quotation?.product_type !== "motor" && <ContinueOrderButton order={order} />}
              </div>
            </div>
            <div className="agent-order-detail-sub-header mt-2">
              <div>
                <small>แผนประกันภัย</small>
                <div>
                  {order.quotes[0]?.addon != undefined ? (
                    <>
                      ชั้น {order.quotes[0].policy_type}
                      <span className="ml-1 inline-block">พ.ร.บ.</span>
                    </>
                  ) : (
                    <>
                      {order.quotation &&
                        order.quotes[0] &&
                        renderYaml(
                          _.get(
                            dataYml.product_type,
                            `${order.quotation.product_type}.${order.quotes[0].policy_type}`
                          )
                        )}
                    </>
                  )}
                </div>
              </div>
              <div>
                <small>ข้อมูลลูกค้า</small>
                <div>{getInsuredByCategory(order.insureds[0], order.insured_category)}</div>
              </div>
              <div>
                <small>{shortInformation[0]}</small>
                <div>{shortInformation[1]}</div>
                <div>{shortInformation[2]}</div>
              </div>
              <div>
                <small>ตัวแทน</small>
                <div>
                  {order.agent?.full_name} ({order.agent?.number})
                </div>
              </div>
            </div>
          </div>

          <OrderDetail
            order={order}
            checkedAddon={checkedAddon}
            installmentInfo={installmentInfo}
            editable={true}
            uploadPaySlipAllowed={true}
            uploadPayerIDCardAllowed={true}
            ordersAPIFactory={defaultStaffOrdersAPIFactory}
            onReload={reload}
            onUploadAttachment={handleUploadAttachment}
            onError={onError}
          />
        </div>
      )}
    </>
  );
};
