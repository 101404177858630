import React from "react";
import { number } from "yup";
import { checkEmpty, dateFormat, getInsuredByCategory } from "~src/helpers/helpers";
import idCardFormat from "~src/helpers/idCardFormat";
import {
  getInsuredDeliveryTypeAsArray,
  getInsuredDeliveryTypeText,
} from "~src/helpers/insuredHelper";
import { Insured } from "~src/types/insured";
import dataYml from "~/src/data.yaml";
import List from "./List";
import { toFullAddress } from "~src/helpers/toFullAddress";
import PolicyStatus from "~src/components/badges/policy/PolicyStatus";
import { OrderPolicy } from "~src/types/order";
import { ReceiptRecipient } from "~src/types/receiptRecipient";

interface InsureDetailProps {
  insure: Insured;
  insuredCategory: string;
  receiptRecipient: ReceiptRecipient
  productType: string;
  policies: OrderPolicy[];
  index: number;
}

const InsuresDetailInfo: React.FC<InsureDetailProps> = ({
  insure,
  insuredCategory,
  receiptRecipient,
  productType,
  policies,
  index,
}): JSX.Element => {

  const beneficiaryInfo = (receiver: string) => {
    if (receiver === "legal_heir") {
      return "ทายาทโดยธรรม";
    }
    return `${insure?.beneficiary?.title} ${insure?.beneficiary?.firstname} ${insure?.beneficiary?.lastname} ความสัมพันธ์ ${insure?.beneficiary?.relationship}`;
  };
  
  return (
    <div className="list-content">
      <List label="ประเภทผู้เอาประกัน" value={dataYml.insured_category[insuredCategory]} />
      {insuredCategory === "legal_entity" && (
        <>
          <List label="ชื่อบริษัทหรือองค์กร" value={checkEmpty(insure.company_name)} />
          <List
            label="เลขประจำตัวผู้เสียภาษีของบริษัท/องค์กร"
            value={idCardFormat(insure.company_tax)}
          />
        </>
      )}
      <List label="ชื่อ-นามสกุล ผู้เอาประกัน" value={getInsuredByCategory(insure)} />
      {insure?.gender && <List label="เพศ" value={checkEmpty(insure.gender)} />}
      {insuredCategory !== "legal_entity" && (
          <>
            <List label="วัน เดือน ปี เกิด" value={dateFormat(insure.birthday)} />
          </>
      )}
      <List label="เบอร์มือถือ" value={checkEmpty(insure.phone_number)} />
      <List label="อีเมล" value={checkEmpty(insure.email)} />
      {productType === "travel" ? (
        <List
          label="เลขที่บัตรประชาชน / พาสปอร์ต"
          value={
            insure?.nationality === "thai" && insure.idcard !== ""
              ? idCardFormat(insure.idcard)
              : insure.passport
          }
        />
      ) : (
        <List
          label="เลขที่บัตรประชาชน / เลขประจําตัวผู้เสียภาษี"
          value={checkEmpty(idCardFormat(insure.idcard))}
        />
      )}

      <List label="ที่อยู่" value={toFullAddress(insure?.addresses[0])} />
      <List
        label="ประเภทการจัดส่งกรมธรรม์"
        value={getInsuredDeliveryTypeText(getInsuredDeliveryTypeAsArray(insure?.delivery_type))}
      />
      {productType === "travel" && policies[index] && (
        <List
          label="สถานะกรมธรรม์"
          value={
            <PolicyStatus status={policies[index].status} modalBodyText={policies[index].message} />
          }
        />
      )}

      {getInsuredDeliveryTypeAsArray(insure?.delivery_type).includes(2) && (
        <List label="ที่อยู่ในการจัดส่งกรมธรรม์" value={toFullAddress(insure?.addresses[1])} />
      )}

      {productType === "travel" ? (
        <>
          <List label="ผู้รับผลประโยชน์" value={beneficiaryInfo(insure?.beneficiary?.receiver)} />
          <List label="รูปแบบใบเสร็จรับเงิน" value={receiptRecipient?.recipient_type ? `ในนาม${dataYml.receipt_recipient_category[receiptRecipient?.recipient_type]}` : "-"} />
          {receiptRecipient?.recipient_type === "legal_entity" && (
            <>
              <List label="ชื่อบริษัท" value={checkEmpty(receiptRecipient?.company_name)} />
              <List label="สาขา" value={checkEmpty(receiptRecipient?.branch)} />
              <List label="เลขประจำตัวผู้เสียภาษี" value={checkEmpty(idCardFormat(receiptRecipient?.company_tax))} />
              <List label="เบอร์มือถือ" value={checkEmpty(receiptRecipient?.phone_number)} />
              <List label="ที่อยู่นิติบุคคล" value={checkEmpty(toFullAddress(receiptRecipient))} />
            </>
          )}
        </>
      ) : (
        <>
          <List label="ผู้รับผลประโยชน์" value={dataYml.beneficiary[insure?.beneficiary.receiver]} />

          {insure?.beneficiary.receiver === "finance" && (
            <List label="ชื่อของสถาบันการเงิน" value={insure?.beneficiary?.full_name} />
          )}
        </>
      )}
    </div>
  );
};

export default InsuresDetailInfo;
