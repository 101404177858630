import dataYml from "~/src/data.yaml";

const countries = [
  {
    value: "ALB",
    label: "Albania",
  },
  {
    value: "DZA",
    label: "Algeria",
  },
  {
    value: "ASM",
    label: "American Samoa",
  },
  {
    value: "AND",
    label: "Andorre",
  },
  {
    value: "AGO",
    label: "Angola",
  },
  {
    value: "AIA",
    label: "Anguilla",
  },
  {
    value: "ATA",
    label: "Antarctica",
  },
  {
    value: "ATG",
    label: "Antigua and Barbuda",
  },
  {
    value: "ARG",
    label: "Argentina",
  },
  {
    value: "ARM",
    label: "Armenia",
  },
  {
    value: "ABW",
    label: "Aruba",
  },
  {
    value: "AUS",
    label: "Australia",
  },
  {
    value: "AUT",
    label: "Austria",
  },
  {
    value: "BHS",
    label: "Bahamas",
  },
  {
    value: "BHR",
    label: "Bahrain",
  },
  {
    value: "BGD",
    label: "Bangladesh",
  },
  {
    value: "BRB",
    label: "Barbade",
  },
  {
    value: "BLR",
    label: "Belarus",
  },
  {
    value: "BEL",
    label: "Belgium",
  },
  {
    value: "BLZ",
    label: "Belize",
  },
  {
    value: "BEN",
    label: "Benin",
  },
  {
    value: "BMU",
    label: "Bermuda",
  },
  {
    value: "BTN",
    label: "Bhutan",
  },
  {
    value: "BOL",
    label: "Bolivia",
  },
  {
    value: "BES",
    label: "Bonaire, Sint Eustatius and Saba",
  },
  {
    value: "BWA",
    label: "Botswana",
  },
  {
    value: "BVT",
    label: "Bouvet Island",
  },
  {
    value: "BRA",
    label: "Brazil",
  },
  {
    value: "IOT",
    label: "British Indian Ocean Territory",
  },
  {
    value: "BRN",
    label: "Brunei",
  },
  {
    value: "BGR",
    label: "Bulgaria",
  },
  {
    value: "BFA",
    label: "Burkina Faso",
  },
  {
    value: "BDI",
    label: "Burundi",
  },
  {
    value: "CPV",
    label: "Cape Verde",
  },
  {
    value: "KHM",
    label: "Cambodia",
  },
  {
    value: "CMR",
    label: "Cameroon",
  },
  {
    value: "CAN",
    label: "Canada",
  },
  {
    value: "CYM",
    label: "Cayman Islands",
  },
  {
    value: "CAF",
    label: "Central African Republic",
  },
  {
    value: "TCD",
    label: "Chad",
  },
  {
    value: "CHL",
    label: "Chile",
  },
  {
    value: "CHN",
    label: "China",
  },
  {
    value: "CXR",
    label: "Christmas Island",
  },
  {
    value: "CCK",
    label: "Cocos (Keeling) Islands",
  },
  {
    value: "COL",
    label: "Colombia",
  },
  {
    value: "COM",
    label: "Comoros",
  },
  {
    value: "COG",
    label: "Congo",
  },
  {
    value: "COD",
    label: "Congo (Dem. Rep.)",
  },
  {
    value: "COK",
    label: "Cook Islands",
  },
  {
    value: "CRI",
    label: "Costa Rica",
  },
  {
    value: "HRV",
    label: "Croatia",
  },
  {
    value: "CUW",
    label: "Curaçao",
  },
  {
    value: "CYP",
    label: "Cyprus",
  },
  {
    value: "CZE",
    label: "Czech Republic",
  },
  {
    value: "CIV",
    label: "Côte D'Ivoire",
  },
  {
    value: "DNK",
    label: "Denmark",
  },
  {
    value: "DJI",
    label: "Djibouti",
  },
  {
    value: "DMA",
    label: "Dominica",
  },
  {
    value: "DOM",
    label: "Dominican Republic",
  },
  {
    value: "ECU",
    label: "Ecuador",
  },
  {
    value: "EGY",
    label: "Egypt",
  },
  {
    value: "SLV",
    label: "El Salvador",
  },
  {
    value: "GNQ",
    label: "Equatorial Guinea",
  },
  {
    value: "ERI",
    label: "Eritrea",
  },
  {
    value: "EST",
    label: "Estonia",
  },
  {
    value: "ETH",
    label: "Ethiopia",
  },
  {
    value: "FLK",
    label: "Falkland Islands",
  },
  {
    value: "FRO",
    label: "Faroe Islands",
  },
  {
    value: "FJI",
    label: "Fiji",
  },
  {
    value: "FIN",
    label: "Finland",
  },
  {
    value: "FRA",
    label: "France",
  },
  {
    value: "GUF",
    label: "French Guiana",
  },
  {
    value: "PYF",
    label: "French Polynesia",
  },
  {
    value: "ATF",
    label: "French Southern Territories",
  },
  {
    value: "GAB",
    label: "Gabon",
  },
  {
    value: "GMB",
    label: "Gambia",
  },
  {
    value: "GEO",
    label: "Georgia",
  },
  {
    value: "DEU",
    label: "Germany",
  },
  {
    value: "GHA",
    label: "Ghana",
  },
  {
    value: "GIB",
    label: "Gibraltar",
  },
  {
    value: "GRC",
    label: "Greece",
  },
  {
    value: "GRL",
    label: "Greenland",
  },
  {
    value: "GRD",
    label: "Grenada",
  },
  {
    value: "GLP",
    label: "Guadeloupe",
  },
  {
    value: "GUM",
    label: "Guam",
  },
  {
    value: "GTM",
    label: "Guatemala",
  },
  {
    value: "GGY",
    label: "Guernsey and Alderney",
  },
  {
    value: "GIN",
    label: "Guinea",
  },
  {
    value: "GNB",
    label: "Guinea-Bissau",
  },
  {
    value: "GUY",
    label: "Guyana",
  },
  {
    value: "HTI",
    label: "Haiti",
  },
  {
    value: "HMD",
    label: "Heard and McDonald Islands",
  },
  {
    value: "VAT",
    label: "Vatican City",
  },
  {
    value: "HND",
    label: "Honduras",
  },
  {
    value: "HKG",
    label: "Hong Kong",
  },
  {
    value: "HUN",
    label: "Hungary",
  },
  {
    value: "ISL",
    label: "Iceland",
  },
  {
    value: "IND",
    label: "India",
  },
  {
    value: "IDN",
    label: "Indonesia",
  },
  {
    value: "IRL",
    label: "Ireland",
  },
  {
    value: "IMN",
    label: "Isle of Man",
  },
  {
    value: "ITA",
    label: "Italy",
  },
  {
    value: "JAM",
    label: "Jamaica",
  },
  {
    value: "JPN",
    label: "Japan",
  },
  {
    value: "JEY",
    label: "Jersey",
  },
  {
    value: "JOR",
    label: "Jordan",
  },
  {
    value: "KAZ",
    label: "Kazakhstan",
  },
  {
    value: "KEN",
    label: "Kenya",
  },
  {
    value: "KIR",
    label: "Kiribati",
  },
  {
    value: "PRK",
    label: "Korea (North)",
  },
  {
    value: "KWT",
    label: "Kuwait",
  },
  {
    value: "LAO",
    label: "Laos",
  },
  {
    value: "LVA",
    label: "Latvia",
  },
  {
    value: "LSO",
    label: "Lesotho",
  },
  {
    value: "LBR",
    label: "Liberia",
  },
  {
    value: "LIE",
    label: "Liechtenstein",
  },
  {
    value: "LTU",
    label: "Lithuania",
  },
  {
    value: "LUX",
    label: "Luxembourg",
  },
  {
    value: "MAC",
    label: "Macao",
  },
  {
    value: "MDG",
    label: "Madagascar",
  },
  {
    value: "MWI",
    label: "Malawi",
  },
  {
    value: "MYS",
    label: "Malaysia",
  },
  {
    value: "MDV",
    label: "Maldives",
  },
  {
    value: "MLI",
    label: "Mali",
  },
  {
    value: "MLT",
    label: "Malta",
  },
  {
    value: "MHL",
    label: "Marshall Islands",
  },
  {
    value: "MTQ",
    label: "Martinique",
  },
  {
    value: "MUS",
    label: "Mauritius",
  },
  {
    value: "MYT",
    label: "Mayotte",
  },
  {
    value: "MEX",
    label: "Mexico",
  },
  {
    value: "FSM",
    label: "Micronesia",
  },
  {
    value: "MDA",
    label: "Moldova",
  },
  {
    value: "MCO",
    label: "Monaco",
  },
  {
    value: "MNG",
    label: "Mongolia",
  },
  {
    value: "MNE",
    label: "Crna Gora",
  },
  {
    value: "MSR",
    label: "Montserrat",
  },
  {
    value: "MAR",
    label: "Morocco",
  },
  {
    value: "MOZ",
    label: "Mozambique",
  },
  {
    value: "MMR",
    label: "Myanmar",
  },
  {
    value: "NAM",
    label: "Namibia",
  },
  {
    value: "NRU",
    label: "Nauru",
  },
  {
    value: "NPL",
    label: "Nepal",
  },
  {
    value: "NLD",
    label: "Netherlands",
  },
  {
    value: "NCL",
    label: "New Caledonia",
  },
  {
    value: "NZL",
    label: "New Zealand",
  },
  {
    value: "NER",
    label: "Niger",
  },
  {
    value: "NGA",
    label: "Nigeria",
  },
  {
    value: "NIU",
    label: "Niue",
  },
  {
    value: "NFK",
    label: "Norfolk Island",
  },
  {
    value: "MNP",
    label: "Northern Mariana Islands",
  },
  {
    value: "NOR",
    label: "Norway",
  },
  {
    value: "OMN",
    label: "Oman",
  },
  {
    value: "PLW",
    label: "Palau",
  },
  {
    value: "PAN",
    label: "Panama",
  },
  {
    value: "PNG",
    label: "Papua New Guinea",
  },
  {
    value: "PRY",
    label: "Paraguay",
  },
  {
    value: "PER",
    label: "Peru",
  },
  {
    value: "PHL",
    label: "Philippines",
  },
  {
    value: "PCN",
    label: "Pitcairn",
  },
  {
    value: "POL",
    label: "Poland",
  },
  {
    value: "PRT",
    label: "Portugal",
  },
  {
    value: "PRI",
    label: "Puerto Rico",
  },
  {
    value: "QAT",
    label: "Qatar",
  },
  {
    value: "ROU",
    label: "Romania",
  },
  {
    value: "RUS",
    label: "Russia",
  },
  {
    value: "RWA",
    label: "Rwanda",
  },
  {
    value: "REU",
    label: "Réunion",
  },
  {
    value: "BLM",
    label: "Saint Barthélemy",
  },
  {
    value: "SHN",
    label: "Saint Helena",
  },
  {
    value: "KNA",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "LCA",
    label: "Saint Lucia",
  },
  {
    value: "MAF",
    label: "Saint Martin",
  },
  {
    value: "SPM",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "VCT",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "WSM",
    label: "Samoa",
  },
  {
    value: "SMR",
    label: "San Marino",
  },
  {
    value: "STP",
    label: "São Tomé and Príncipe",
  },
  {
    value: "SAU",
    label: "Saudi Arabia",
  },
  {
    value: "SEN",
    label: "Senegal",
  },
  {
    value: "SRB",
    label: "Serbia",
  },
  {
    value: "SYC",
    label: "Seychelles",
  },
  {
    value: "SLE",
    label: "Sierra Leone",
  },
  {
    value: "SGP",
    label: "Singapore",
  },
  {
    value: "SXM",
    label: "Sint Maarten",
  },
  {
    value: "SVK",
    label: "Slovakia",
  },
  {
    value: "SVN",
    label: "Slovenia",
  },
  {
    value: "SLB",
    label: "Solomon Islands",
  },
  {
    value: "SOM",
    label: "Somalia",
  },
  {
    value: "ZAF",
    label: "South Africa",
  },
  {
    value: "SGS",
    label: "South Georgia",
  },
  {
    value: "SSD",
    label: "South Sudan",
  },
  {
    value: "ESP",
    label: "Spain",
  },
  {
    value: "LKA",
    label: "Sri Lanka",
  },
  {
    value: "SDN",
    label: "Sudan",
  },
  {
    value: "SUR",
    label: "Suriname",
  },
  {
    value: "SJM",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "SWZ",
    label: "Swaziland",
  },
  {
    value: "SWE",
    label: "Sweden",
  },
  {
    value: "CHE",
    label: "Switzerland",
  },
  {
    value: "TWN",
    label: "Taiwan",
  },
  {
    value: "TZA",
    label: "Tanzania",
  },
  {
    value: "TLS",
    label: "East Timor",
  },
  {
    value: "TGO",
    label: "Togo",
  },
  {
    value: "TKL",
    label: "Tokelau",
  },
  {
    value: "TON",
    label: "Tonga",
  },
  {
    value: "TTO",
    label: "Trinidad and Tobago",
  },
  {
    value: "TUN",
    label: "Tunisia",
  },
  {
    value: "TUR",
    label: "Turkey",
  },
  {
    value: "TCA",
    label: "Turks and Caicos Islands",
  },
  {
    value: "TUV",
    label: "Tuvalu",
  },
  {
    value: "UGA",
    label: "Uganda",
  },
  {
    value: "UKR",
    label: "Ukraine",
  },
  {
    value: "ARE",
    label: "United Arab Emirates",
  },
  {
    value: "GBR",
    label: "United Kingdom",
  },
  {
    value: "UMI",
    label: "United States Minor Outlying Islands",
  },
  {
    value: "USA",
    label: "United States",
  },
  {
    value: "URY",
    label: "Uruguay",
  },
  {
    value: "VUT",
    label: "Vanuatu",
  },
  {
    value: "VEN",
    label: "Venezuela",
  },
  {
    value: "VNM",
    label: "Vietnam",
  },
  {
    value: "VGB",
    label: "British Virgin Islands",
  },
  {
    value: "VIR",
    label: "Virgin Islands of the United States",
  },
  {
    value: "WLF",
    label: "Wallis and Futuna",
  },
  {
    value: "ESH",
    label: "Western Sahara",
  },
  {
    value: "YEM",
    label: "Yemen",
  },
  {
    value: "ZMB",
    label: "Zambia",
  },
  {
    value: "ZWE",
    label: "Zimbabwe",
  },
  {
    value: "ALA",
    label: "Åland Islands",
  },
];

const travellerOptions = [
  {
    label: "1 คน",
    value: 1,
  },
  {
    label: "2 คน",
    value: 2,
  },
  {
    label: "3 คน",
    value: 3,
  },
  {
    label: "4 คน",
    value: 4,
  },
  {
    label: "5 คน",
    value: 5,
  },
  {
    label: "6 คน",
    value: 6,
  },
  {
    label: "7 คน",
    value: 7,
  },
  {
    label: "8 คน",
    value: 8,
  },
  {
    label: "9 คน",
    value: 9,
  },
  {
    label: "เดินทางมากกว่า 10คน",
    value: "n",
  },
];

const insurerOptions = [
  {
    label: "เมืองไทยประกันภัย",
    value: "MTI",
  },
];

const popularCountries = [
  {
    value: "JPN",
    label: "Japan",
  },
  {
    value: "schengen",
    label: "Schengen",
  },
  {
    value: "USA",
    label: "United States",
  },
  {
    value: "GBR",
    label: "United Kingdom",
  },
  {
    value: "HKG",
    label: "Hong Kong",
  },
  {
    value: "KOR",
    label: "South Korea",
  },
  {
    value: "CHN",
    label: "China",
  },
  {
    value: "aec",
    label: "Aec",
  },
  {
    value: "asia",
    label: "Asia",
  },
];

const titleTHOptions = [
  { value: "คุณ", label: "คุณ" },
  { value: "นาย", label: "นาย" },
  { value: "นาง", label: "นาง" },
  { value: "นางสาว", label: "นางสาว" },
  { value: "เด็กชาย", label: "เด็กชาย" },
  { value: "เด็กหญิง", label: "เด็กหญิง" },
];

const titleENOptions = [
  { value: "MR.", label: "MR." },
  { value: "MS.", label: "MS." },
  { value: "MRS.", label: "MRS." },
];

const nationalityOptions = [
  { value: "thai", label: "ไทย" },
  { value: "expat", label: "ต่างชาติ" },
];

const insuredTypeOption = [
  { label: dataYml.insured_category.person, value: "person" },
  { label: dataYml.insured_category.legal_entity, value: "legal_entity" },
];

const titleAgentTHOptions = [
  { value: "นาย", label: "นาย" },
  { value: "นาง", label: "นาง" },
  { value: "นางสาว", label: "นางสาว" },
  { value: "บริษัท", label: "บริษัท" },
];

const titleRenewTHOptions = [
  { value: "คุณ", label: "คุณ" },
  { value: "นาย", label: "นาย" },
  { value: "นาง", label: "นาง" },
  { value: "นางสาว", label: "นางสาว" },
  { value: "เด็กชาย", label: "เด็กชาย" },
  { value: "เด็กหญิง", label: "เด็กหญิง" },
];

const titleLegalEntityTHOptions = [
  { value: "บริษัท", label: "บริษัท" },
  { value: "ส่วนราชการ", label: "ส่วนราชการ" },
  { value: "กรม", label: "กรม" },
  { value: "กระทรวง", label: "กระทรวง" },
  { value: "คณะ", label: "คณะ" },
  { value: "คณะบุคคล", label: "คณะบุคคล" },
  { value: "ฝ่าย", label: "ฝ่าย" },
  { value: "มหาวิทยาลัย", label: "มหาวิทยาลัย" },
  { value: "มูลนิธิ", label: "มูลนิธิ" },
  { value: "โรงพยาบาล", label: "โรงพยาบาล" },
  { value: "โรงเรียน", label: "โรงเรียน" },
  { value: "โรงแรม", label: "โรงแรม" },
  { value: "วัด", label: "วัด" },
  { value: "ศาล", label: "ศาล" },
  { value: "สถาบัน", label: "สถาบัน" },
  { value: "สาขา", label: "สาขา" },
  { value: "สำนัก", label: "สำนัก" },
  { value: "องค์กร", label: "องค์กร" },
  { value: "องค์การ", label: "องค์การ" },
  { value: "Company", label: "Company" },
  { value: "Limited", label: "Limited" },
  { value: "Private", label: "Private" },
  { value: "Public", label: "Public" },
  { value: "Company", label: "Company" },
  { value: "กลุ่ม", label: "กลุ่ม" },
  { value: "กองทัพเรือ", label: "กองทัพเรือ" },
  { value: "กองทุน", label: "กองทุน" },
  { value: "การไฟฟ้า", label: "การไฟฟ้า" },
  { value: "กิจการร่วมค้า", label: "กิจการร่วมค้า" },
  { value: "โกดัง", label: "โกดัง" },
  { value: "คณะบุคคล", label: "คณะบุคคล" },
  { value: "โครงการ", label: "โครงการ" },
  { value: "เทศบาล", label: "เทศบาล" },
  { value: "ธนาคาร", label: "ธนาคาร" },
  { value: "นิติบุคคล", label: "นิติบุคคล" },
  { value: "บจก.", label: "บจก." },
  { value: "บมจ.", label: "บมจ." },
  { value: "บริษัทเงินทุน", label: "บริษัทเงินทุน" },
  { value: "บริษัทเงินทุนหลักทรัพย์", label: "บริษัทเงินทุนหลักทรัพย์" },
  { value: "บริษัทหลักทรัพย์", label: "บริษัทหลักทรัพย์" },
  { value: "มหาวิทยาลัย", label: "มหาวิทยาลัย" },
  { value: "ร้าน", label: "ร้าน" },
  { value: "โรงงาน", label: "โรงงาน" },
  { value: "โรงภาพยนต์", label: "โรงภาพยนต์" },
  { value: "โรงแรม", label: "โรงแรม" },
  { value: "โรงสี", label: "โรงสี" },
  { value: "วิทยาลัย", label: "วิทยาลัย" },
  { value: "ศูนย์", label: "ศูนย์" },
  { value: "สถานี", label: "สถานี" },
  { value: "สถานีตำรวจ", label: "สถานีตำรวจ" },
  { value: "สถาบัน", label: "สถาบัน" },
  { value: "สมาคม", label: "สมาคม" },
  { value: "สโมสร", label: "สโมสร" },
  { value: "สวัสดิการ", label: "สวัสดิการ" },
  { value: "สหกรณ์", label: "สหกรณ์" },
  { value: "สหกรณ์ออมทรัพย์", label: "สหกรณ์ออมทรัพย์" },
  { value: "สำนักงาน", label: "สำนักงาน" },
  { value: "หอพัก", label: "หอพัก" },
  { value: "ห้าง", label: "ห้าง" },
  { value: "ห้างหุ้นส่วน", label: "ห้างหุ้นส่วน" },
  { value: "ห้างหุ้นส่วนจำกัด", label: "ห้างหุ้นส่วนจำกัด" },
  { value: "ห้างหุ้นส่วนสามัญ", label: "ห้างหุ้นส่วนสามัญ" },
  { value: "ห้างหุ้นส่วนสามัญนิติบุคคล", label: "ห้างหุ้นส่วนสามัญนิติบุคคล" },
  { value: "อื่นๆ", label: "อื่นๆ" },
  { value: "อู่", label: "อู่" },
];

const titleRenewLegalEntityTHOptions = [
  { value: "คุณ", label: "คุณ" },
  { value: "นาย", label: "นาย" },
  { value: "นาง", label: "นาง" },
  { value: "นางสาว", label: "นางสาว" },
  { value: "เด็กชาย", label: "เด็กชาย" },
  { value: "เด็กหญิง", label: "เด็กหญิง" },
  { value: "บริษัท", label: "บริษัท" },
];

const beneficiaryOptions = [
  { label: "บิดา", value: "บิดา" },
  { label: "มารดา", value: "มารดา" },
  { label: "คู่สมรส", value: "คู่สมรส" },
  { label: "บุตร", value: "บุตร" },
  { label: "พี่", value: "พี่" },
  { label: "น้อง", value: "น้อง" },
  { label: "หลาน", value: "หลาน" },
  { label: "ปู่/ตา", value: "ปู่/ตา" },
  { label: "ย่า/ยาย", value: "ย่า/ยาย" },
  { label: "ป้า/น้า", value: "ป้า/น้า" },
  { label: "ลุง/อา", value: "ลุง/อา" },
];

const activeStatusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Waiting Approval", value: "waiting_approval" },
];

const licenseTypeOptions = [
  { label: "ใบอนุญาตประกันชีวิต", value: "life" },
  { label: "ใบอนุญาตประกันวินาศภัย", value: "non-life" },
];

const deliveryAddressTypeOptions = [
  {
    label: "ที่อยู่ผู้เอาประกัน",
    value: "insured_address",
  },
  {
    label: "ที่อยู่อื่นๆ",
    value: "other_address",
  },
];

const deliveryTypeOptions = [
  { label: "E-policy", value: 1 },
  { label: "Document", value: 2 },
];

const orderCustomPaymentChannel = [
  { label: "TRANSFER", value: "bank_transfer" },
  { label: "VEDC", value: "vedc" },
  { label: "Credit card (full amount) - Kbank", value: "kbank_cc" },
  { label: "Credit card (full amount) - 2C2P", value: "2c2p" },
  { label: "QR (full amount) - Kbank", value: "qr" },
  { label: "ผ่อนเงินสด 3 เดือน(งวดแรก 25%)", value: "installment_3" },
  { label: "ผ่อนเงินสด 6 เดือน(งวดแรก 25%)", value: "installment_6" },
  { label: "ผ่อนเงินสด 10 เดือน(งวดแรก 25%)", value: "installment_10" },
  { label: "ตัดบัตรเครดิตกับบริษัทประกัน (เต็มจำนวน)", value: "insurer_cc" },
  { label: "ตัดบัตรเครดิตกับบริษัทประกัน (ผ่อน)", value: "insurer_cc_installment" },
  // { label: "installment credit card 3 เดือน", value: "installment_cc_3" },
  // { label: "installment credit card 6 เดือน", value: "installment_cc_6" },
  // { label: "installment credit card 10 เดือน", value: "installment_cc_10" },
  // { label: "2c2p Credit card ผ่อน 3 เดือน", value: "installment_2c2p_3" },
  // { label: "2c2p Credit card ผ่อน 6 เดือน", value: "installment_2c2p_6" },
  // { label: "2c2p Credit card ผ่อน 10 เดือน", value: "installment_2c2p_10" },
  { label: "Credit Term", value: "credit_term" },
];

const popularCarBrands = [
  { label: "Toyota", value: "Toyota" },
  { label: "Honda", value: "Honda" },
  { label: "Isuzu", value: "Isuzu" },
  { label: "Nissan", value: "Nissan" },
  { label: "Mazda", value: "Mazda" },
  { label: "BMW", value: "BMW" },
  { label: "Mercedes-Benz", value: "Mercedes-Benz" },
];

const optionsAddonCmi = [
  { label: "645.21", value: "110" },
  { label: "967.28", value: "320" },
  { label: "1182.35", value: "210" },
];

const vehicleType = [
  { label: "110", value: "110" },
  { label: "320", value: "320" },
  { label: "210", value: "210" },
  { label: "420", value: "420" },
  { label: "520", value: "520" },
  { label: "610", value: "610" },
];

const bankOptions = [{ value: "กสิกร", label: "กสิกร" }];

const userStatusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "waiting_approval", label: "Waiting Approval" },
];

const mtlSalesBranchOptions = [
  { value: "MTLP 1", label: "MTLP 1" },
  { value: "MTLP 2", label: "MTLP 2" },
  { value: "MTLP 3", label: "MTLP 3" },
  { value: "MTLP 4", label: "MTLP 4" },
  { value: "MTLP 5", label: "MTLP 5" },
  { value: "MTLP 6", label: "MTLP 6" },
  { value: "MTLP 7", label: "MTLP 7" },
  { value: "MTLP 8", label: "MTLP 8" },
  { value: "MTLP 9", label: "MTLP 9" },
  { value: "MTLP 10", label: "MTLP 10" },
  { value: "MTLP 12", label: "MTLP 12" },
  { value: "MTLP 16", label: "MTLP 16" },
  { value: "MTLP 20", label: "MTLP 20" },
  { value: "MTLP 23", label: "MTLP 23" },
  { value: "MTLP 28", label: "MTLP 28" },
  { value: "MTLP 33", label: "MTLP 33" },
  { value: "MTLP 35", label: "MTLP 35" },
  { value: "MTLP 39", label: "MTLP 39" },
  { value: "MTLP 42", label: "MTLP 42" },
  { value: "MTLP 48", label: "MTLP 48" },
  { value: "MTLP 51", label: "MTLP 51" },
  { value: "MTLP 52", label: "MTLP 52" },
  { value: "MTLP 53", label: "MTLP 53" },
  { value: "MTLP 54", label: "MTLP 54" },
  { value: "MTLP 55", label: "MTLP 55" },
  { value: "MTLP 59", label: "MTLP 59" },
  { value: "MTLP 71", label: "MTLP 71" },
  { value: "MTLP 72", label: "MTLP 72" },
  { value: "MTLP 73", label: "MTLP 73" },
  { value: "MTLP 74", label: "MTLP 74" },
  { value: "MTLP 75", label: "MTLP 75" },
  { value: "MTLP 76", label: "MTLP 76" },
  { value: "MTLP 88", label: "MTLP 88" },
  { value: "MTLP 99", label: "MTLP 99" },
  { value: "MTL Next", label: "MTL Next" },
];
const gender = [
  { label: "ชาย", value: "ชาย" },
  { label: "หญิง", value: "หญิง" },
];

const travelStep = [
  { label: "ข้อมูลผู้ถือกรมธรรม์" },
  { label: "ยืนยันข้อมูล" },
  { label: "ชำระเงิน" },
];

const educationLevelOptions = [
  { value: "primary", label: "ประถมศึกษา" },
  { value: "secondary", label: "มัธยมศึกษา" },
  { value: "vocational", label: "ปวช./ปวส." },
  { value: "bachelor", label: "ปริญญาตรี" },
  { value: "graduate_or_higher", label: "ปริญญาโท หรือสูงกว่า" },
];

const commissionType = [
  { label: "%", value: "PERCENT" },
  { label: "฿", value: "BAHT" },
];

const attachmentStatusOptions = [
  { label: "ครบ", value: "all" },
  { label: "ไม่ครบ", value: "partial"}
];

export {
  travelStep,
  travellerOptions,
  insurerOptions,
  countries,
  popularCountries,
  titleTHOptions,
  titleAgentTHOptions,
  titleRenewTHOptions,
  titleRenewLegalEntityTHOptions,
  titleLegalEntityTHOptions,
  beneficiaryOptions,
  activeStatusOptions,
  licenseTypeOptions,
  deliveryAddressTypeOptions,
  deliveryTypeOptions,
  insuredTypeOption,
  orderCustomPaymentChannel,
  popularCarBrands,
  optionsAddonCmi,
  vehicleType,
  bankOptions,
  userStatusOptions,
  mtlSalesBranchOptions,
  gender,
  nationalityOptions,
  titleENOptions,
  educationLevelOptions,
  commissionType,
  attachmentStatusOptions,
};
