import React from "react";
import { dateFormat, parseDate } from "~src/helpers";
import { checkEmpty } from "~src/helpers/helpers";
import idCardFormat from "~src/helpers/idCardFormat";
import {
  getInsuredDeliveryTypeAsArray,
  getInsuredDeliveryTypeText,
} from "~src/helpers/insuredHelper";
import { toFullAddress } from "~src/helpers/toFullAddress";
import { Group, List } from "~src/pages/agent/checkout/summary/SummaryList";
import { Insured } from "~src/types/insured";
import { ReceiptRecipient } from "~src/types/receiptRecipient";

interface SummaryInsuredProps {
  insures: Insured;
  receiptRecipient: ReceiptRecipient;
}

const SummaryInsured: React.FC<SummaryInsuredProps> = ({ insures, receiptRecipient }): JSX.Element => {
  const beneficiaryInfo = (receiver: string) => {
    if (receiver === "legal_heir") {
      return "ทายาทโดยธรรม";
    }
    return `${insures?.beneficiary?.title} ${insures?.beneficiary?.firstname} ${insures?.beneficiary?.lastname} ความสัมพันธ์ ${insures?.beneficiary?.relationship}`;
  };

  const isLegalEntityReceipt = receiptRecipient?.recipient_type === "legal_entity";

  return (
    <Group className="mt-2">
      <List label="เพศ" value={insures?.gender} />
      <List
        label="ชื่อ-นามสกุล ผู้เอาประกัน"
        value={`${insures?.title} ${insures?.firstname} ${insures?.lastname}`}
      />
      <List
        label="วัน เดือน ปีเกิด"
        value={checkEmpty(dateFormat(parseDate(insures?.birthday), "dd/MM/yyyy"))}
      />
      <List
        label="เลขที่บัตรประชาชน/พาสปอร์ต"
        value={
          insures?.nationality === "thai" && insures.idcard !== ""
            ? idCardFormat(insures.idcard)
            : insures.passport
        }
      />
      <List label="สัญชาติ" value={insures?.nationality === "thai" ? "ไทย" : "ต่างชาติ"} />
      <List label="เบอร์มือถือ" value={checkEmpty(insures?.phone_number)} />
      <List label="อีเมล" value={checkEmpty(insures?.email)} />
      <List
        label="ประเภทการจัดส่งกรมธรรม์"
        value={getInsuredDeliveryTypeText(getInsuredDeliveryTypeAsArray(insures?.delivery_type))}
      />
      {getInsuredDeliveryTypeAsArray(insures?.delivery_type).includes(2) && (
        <List label="ที่อยู่ในการจัดส่งกรมธรรม์" value={toFullAddress(insures?.addresses[1])} />
      )}
      <List label="ผู้รับผลประโยชน์" value={beneficiaryInfo(insures?.beneficiary?.receiver)} />
      <List label="ที่อยู่" value={toFullAddress(insures?.addresses[0])} />
      <List label="รูปแบบใบเสร็จรับเงิน" value={isLegalEntityReceipt ? "ในนามบริษัท" : "ในนามบุคคลธรรมดา"} />
      {isLegalEntityReceipt && (
        <>
          <List label="ชื่อบริษัท" value={receiptRecipient.company_name} />
          <List label="สาขา" value={receiptRecipient.branch} />
          <List label="เลขที่ประจำตัวผู้เสียภาษี" value={idCardFormat(receiptRecipient.company_tax)} />
          <List label="เบอร์มือถือ" value={receiptRecipient.phone_number} />
          <List label="ที่อยู่นิติบุคคล" value={toFullAddress(receiptRecipient)} />
        </>
      )}
    </Group>
  );
};

export default SummaryInsured;
