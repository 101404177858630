import React from "react";
import CommonModal from "~src/components/modal/common";
import { useAppDispatch, useAppSelector } from "~src/store";
import ProductCard from "./components/ProductCard";
import {
  closeCoverageModal,
  coverageModalState,
  travelProductsData,
  travelTypeState,
} from "./redux";
import CoverageModal from "./CoverageModal";

const ProductList = () => {
  const products = useAppSelector(travelProductsData);
  const coverageModal = useAppSelector(coverageModalState);
  const travelType = useAppSelector(travelTypeState);

  const dispatch = useAppDispatch();

  const url =
    "https://storage.googleapis.com/asia-gcs-mtb-storage-project-marketing/coverage-tables";
  //get pdf
  const renderPdfPath = (insurerCode: string, productName: string, policyType: string) => {
    const formattedInsurerCode = insurerCode.toLocaleLowerCase();
    const formattedProductName = productName.replace(/ /g, "_");
    return `${url}/${formattedInsurerCode}/${formattedProductName}_${policyType}.pdf`;
  };

  return (
    <>
      {products.map((data, index) => (
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" key={index}>
          <ProductCard
            data={data}
            coveragePdfUrl={
              data.insurer_code === "MSIG"
                ? renderPdfPath(data.insurer_code, data.product_name, travelType)
                : null
            }
            remark={
              data.insurer_code === "MSIG"
                ? travelType === "one_time"
                  ? "อายุที่รับประกัน 1 - 70 ปี"
                  : "อายุที่รับประกัน 15 - 65 ปี"
                : null
            }
          />
        </div>
      ))}

      <CommonModal open={coverageModal.open} onClose={() => dispatch(closeCoverageModal())}>
        <CoverageModal />
      </CommonModal>
    </>
  );
};

export default ProductList;
