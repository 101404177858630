import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useFormContext, Controller } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import classNames from "classnames";
import { format } from "date-fns";

import { FormGroup, TextField, SelectField, UploadField, Checkbox } from "~/src/components/form";
import { messages } from "~/src/helpers/messages";
import { orderCustomPaymentChannel } from "~/src/helpers/options";
import DatePicker from "~/src/components/Forms/DatePicker";
import { createAttachmentFromFile } from "~src/helpers/attachments";
import { Attachment } from "~src/types/attachment";
import { paymentInfo } from "~/src/states/orderCustom";

const PaymentPanel: React.FC = (): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { control, errors, register, setValue } = useFormContext();

  const [imageAttachment, setImageAttachment] = useState<Attachment | undefined>();
  const [imageFile, setImageFile] = useState<File | Object | undefined>();
  const [{ type }, setPaymentInfo] = useRecoilState(paymentInfo);

  useDeepCompareEffectNoCheck(() => {
    const _attachment = imageFile ? createAttachmentFromFile(imageFile) : undefined;
    _attachment &&
      setPaymentInfo((prevState) => ({
        ...prevState,
        payslip: _attachment,
      }));
  }, [imageFile]);

  const handleChangeUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;

    setImageFile(files[0]);
  };

  const resetAdditionalPayment = (paymentType: string) => {
    setValue(`order.details.${paymentType}`, "");
  };

  const isPaymentTypeInstallment = (paymentType: string) => {
    const processedPaymentType = paymentType.split("_");
    return processedPaymentType[0] === "installment" && processedPaymentType.length === 2;
  };

  return (
    <div className="p-4 w-full bg-white shadow rounded-5">
      <h6 className="flex items-center justify-between p-1 px-2 text-sm font-bold bg-gray-100">
        ข้อมูลการชำระเงิน
        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
          className="flex items-center justify-center w-6 h-6 bg-white shadow outline-none rounded-5 focus:outline-none"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </button>
      </h6>

      <div className={classNames(expanded ? "block" : "hidden")}>
        <div className="flex flex-col mt-4 px-2 gap-4">
          <FormGroup label="Payment channel" require>
            <SelectField
              name="payment.type"
              inputRef={register(messages.required)}
              error={errors?.payment?.type?.message}
              options={orderCustomPaymentChannel}
              onChange={({ currentTarget }) =>
                setPaymentInfo((prevState) => ({
                  ...prevState,
                  type: currentTarget.value,
                }))
              }
            />
          </FormGroup>
          {type !== "none" && (
            <>
              {type !== "credit_term" && (
                <>
                  <FormGroup label="Payment Date" require>
                    <Controller
                      name="payment.paid_at"
                      rules={messages.required}
                      control={control}
                      render={({ onChange, value }) => {
                        return (
                          <>
                            <DatePicker
                              value={value}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date: Date): void => {
                                onChange(format(date, "dd/MM/yyyy"));
                              }}
                            />
                            {errors?.payment?.paid_at?.message && (
                              <span className="text-xs text-red-600">
                                {errors?.payment?.paid_at?.message}
                              </span>
                            )}
                          </>
                        );
                      }}
                    />
                  </FormGroup>

                  <FormGroup label="Payment Time" require>
                    <Controller
                      name="payment.paid_at_time"
                      control={control}
                      rules={messages.required}
                      render={({ onChange, value }) => {
                        return (
                          <>
                            <DatePicker
                              selected={value}
                              showTimeInput
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              onChange={(date: Date): void => {
                                onChange(date);
                              }}
                            />
                            {errors?.payment?.paid_at_time?.message && (
                              <span className="text-xs text-red-600">
                                {errors?.payment?.paid_at_time?.message}
                              </span>
                            )}
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                </>
              )}

              <FormGroup label="Amount Paid" require>
                <TextField
                  name="payment.amount"
                  type="number"
                  min="0"
                  step="any"
                  inputRef={register(messages.required)}
                  error={errors?.payment?.amount?.message}
                />
              </FormGroup>

              <FormGroup label="Receipt File">
                <UploadField
                  name="payment.payslip"
                  inputRef={register(messages.allowFileType)}
                  accept="image/*"
                  onChange={handleChangeUpload}
                  error={errors?.payment?.payslip?.message}
                />
              </FormGroup>

              {isPaymentTypeInstallment(type) && (
                <>
                  <FormGroup label="สัญญาผ่อนนายหน้า">
                    <UploadField
                      name="payment_info.agent_installment_contract"
                      inputRef={register(messages.allowFileType)}
                      accept="image/*,application/pdf"
                      onChange={handleChangeUpload}
                      error={errors?.payment_info?.agent_installment_contract?.message}
                    />
                  </FormGroup>

                  <FormGroup label="สัญญาผ่อนลูกค้า">
                    <UploadField
                      name="payment_info.customer_installment_contract"
                      inputRef={register(messages.allowFileType)}
                      accept="image/*,application/pdf"
                      onChange={handleChangeUpload}
                      error={errors?.payment_info?.customer_installment_contract?.message}
                    />
                  </FormGroup>

                  <FormGroup label="Selfie">
                    <UploadField
                      name="payment_info.installment_selfie_image"
                      inputRef={register(messages.allowImageFileType)}
                      accept="image/*"
                      onChange={handleChangeUpload}
                      error={errors?.payment_info?.installment_selfie_image?.message}
                    />
                  </FormGroup>

                  <FormGroup label="แนบบัตรประชาชน">
                    <UploadField
                      name="order.payer_id_card"
                      inputRef={register(messages.allowFileType)}
                      accept="image/*,application/pdf"
                      onChange={handleChangeUpload}
                      error={errors?.order?.payer_id_card?.message}
                    />
                  </FormGroup>
                </>
              )}

              <FormGroup label="Remark">
                <TextField name="payment.description" inputRef={register} />
              </FormGroup>
            </>
          )}

          <div className="grid grid-cols-2 justify-items-start mb-4">
            <Checkbox
              id="payment_insurer"
              inputRef={register}
              label="จ่ายตรงบริษัทประกัน"
              name="order.details.payment_insurer"
              onChange={() => resetAdditionalPayment("payment_mtb")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPanel;
