import React from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";
import { FormGroup, TextField } from "~/src/components/form";
import { Radio } from "~/src/components/form";
import { ReceiptRecipient } from "~src/types/receiptRecipient";
import Address from "~src/pages/public/checkout/components/Address";
import { messages } from "~src/helpers/messages";

import { receiptRecipientAtom } from "~src/states/orderCheckout";

interface ReceiptFormProps {
  fieldName: string;
  setValue: any;
  getValues: any;
  clearErrors: any;
  readOnly?: boolean;
  maxLength?: number;
}

const ReceiptForm: React.FC<ReceiptFormProps> = ({
  fieldName,
  setValue,
  getValues,
  clearErrors,
  readOnly = false,
  maxLength = 60,
}): JSX.Element => {
  const { register, errors, control } = useFormContext();
  const [receiptRecipientInfo, setReceiptRecipientInfo] = useRecoilState(receiptRecipientAtom)

  const updateNumberValue = (event: React.ChangeEvent<HTMLInputElement>, onChange) => {
    if (/^[0-9]*$/.test(event.target.value)) {
      onChange(event.target.value)
    }
  }

  return (
    <div>
      <div className="space-y-16">
        <Radio
          id={`person-${fieldName}`}
          name={`${fieldName}[recipient_type]`}
          value="person"
          inputRef={register(messages.required)}
          checked={receiptRecipientInfo?.recipient_type === "person" || receiptRecipientInfo?.recipient_type === null}
          label="ในนามบุคคลธรรมดา"
          onChange={() => {
            setReceiptRecipientInfo({
            ...receiptRecipientInfo,
            recipient_type: "person"
          })}}
        />
        <Radio
          id={`legal_entity-${fieldName}`}
          name={`${fieldName}[recipient_type]`}
          value="legal_entity"
          inputRef={register(messages.required)}
          checked={receiptRecipientInfo?.recipient_type === "legal_entity"}
          label="ในนามบริษัท หรือนิติบุคคล"
          onChange={() => {
            setReceiptRecipientInfo({
            ...receiptRecipientInfo,
            recipient_type: "legal_entity"
          })}}
        />
      </div>
      {receiptRecipientInfo.recipient_type == "legal_entity" && (
        <div className="grid gap-4 col-span-8 mt-3">
          <FormGroup 
            label="ชื่อบริษัท"
            htmlForLabel="company_name"
            className="col-span-2"
            require
          >
            <Controller
              id="company_name"
              name={`${fieldName}[company_name]`}
              defaultValue={receiptRecipientInfo?.company_name}
              rules={messages.required}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={errors?.company_name?.message}
                  maxLength={maxLength}
                />
              )}
            />
          </FormGroup>

          <FormGroup 
            label="สาขา"
            className="col-span-2"
            htmlForLabel="branch"
            errorRequire="(สำนักงานใหญ่ หรือรหัสสาขา)"
            require
          >
            <Controller
              id="branch"
              name={`${fieldName}[branch]`}
              defaultValue={receiptRecipientInfo?.branch}
              rules={messages.required}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={errors?.branch?.message}
                  maxLength={maxLength}
                />
              )}
            />
          </FormGroup>

          <FormGroup 
            label="เลขประจำตัวผู้เสียภาษี"
            className="col-span-2"
            htmlForLabel="company_tax"
            require
          >
            <Controller
              id="company_tax"
              name={`${fieldName}[company_tax]`}
              defaultValue={receiptRecipientInfo?.company_tax}
              rules={{ ...messages.required, ...messages.validateCompanyTax }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  value={value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateNumberValue(e, onChange)
                  }}
                  error={errors?.company_tax?.message}
                  maxLength={13}
                />
              )}
            />
          </FormGroup>

          <FormGroup 
            label="เบอร์โทรศัพท์"
            className="col-span-2"
            htmlForLabel="phone_number"
            require
          >
            <Controller
              id="phone_number"
              name={`${fieldName}[phone_number]`}
              defaultValue={receiptRecipientInfo?.phone_number}
              rules={{ ...messages.required, ...messages.mobileNumber }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  value={value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateNumberValue(e, onChange)
                  }}
                  error={errors?.phone_number?.message}
                  maxLength={10}
                />
              )}
            />
          </FormGroup>

          <Address
            initialValue={receiptRecipientInfo}
            register={register}
            name={`${fieldName}`}
            errors={errors?.address}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            readOnly={readOnly}
            maxLength={maxLength}
          />
        </div>
      )}
    </div>
  );
}

export default ReceiptForm;