import React, { useMemo } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { dateFormat, parseDate } from "~src/helpers";
import { messages } from "~src/helpers/messages";
import { gender, nationalityOptions, titleENOptions } from "~src/helpers/options";
import { Insured } from "~src/types/insured";
import { Quotation } from "~src/types/quotation";
import { Quote } from "~src/types/quote";
import { DatePickerField, FormGroup, SelectField, TextField } from "../form";

interface Props {
  productDetails: any;
  insuredsInfo: Insured;
  fieldName: string;
  index: number;
  quotationInfo?: Quotation;
  quotes?: Quote[];
}

const InsureInfoForm: React.FC<Props> = ({
  insuredsInfo,
  fieldName,
  index,
  productDetails,
  quotationInfo,
  quotes,
}): JSX.Element => {
  const { register, errors, control } = useFormContext();
  const [policyPeriodLabel, setPolicyPeriodLabel] = React.useState("");
  React.useEffect(() => {
    setPolicyPeriodLabel("ประกันแบบรายปี รับประกันอายุ 15-65 ปีบริบูรณ์");
    if (quotes?.[0].policy_period === "one_time") {
      setPolicyPeriodLabel("ประกันแบบรายครั้ง รับประกันอายุ 1-70 ปีบริบูรณ์");
    }
  }, [quotes]);

  const watchInsuredTitle = useWatch({
    control,
    name: `${fieldName}[title]`,
    defaultValue: insuredsInfo?.title,
  });

  const watchInsuredNationality = useWatch({
    control,
    name: `${fieldName}[nationality]`,
    defaultValue: insuredsInfo?.nationality,
  });

  const genderByTitle = useMemo(() => {
    if (watchInsuredTitle.includes("S")) return [{ label: "หญิง", value: "หญิง" }];

    return [{ label: "ชาย", value: "ชาย" }];
  }, [watchInsuredTitle]);

  const date = new Date();
  const { coverage_from_age, coverage_to_age } = productDetails;

  let coverageFromYear = date.getFullYear() - coverage_from_age;
  let coverageToYear = date.getFullYear() - coverage_to_age;

  if (quotationInfo?.product_type === "travel") {
    const quote = quotes?.[0];
    if (quote.policy_period === "one_time") {
      coverageFromYear = date.getFullYear() - 1;
      coverageToYear = date.getFullYear() - 70;
    } else {
      const currentYear = date.getFullYear();
      coverageFromYear = currentYear - 15;
      coverageToYear = currentYear - 65;
    }
  }

  const datePickerMaxDate = parseDate(
    dateFormat(new Date(coverageFromYear, date.getMonth(), date.getDate()))
  );
  const datePickerMinDate = parseDate(
    dateFormat(new Date(coverageToYear, date.getMonth(), date.getDate()))
  );

  return (
    <>
      <div className="row">
        <div className="col-6">
          <FormGroup label="คำนำหน้าชื่อ" htmlForLabel="insured_title" require>
            <input
              type="hidden"
              name={`${fieldName}[id]`}
              value={insuredsInfo?.id}
              ref={register}
            />
            <input type="hidden" name="order.insured_category" value="person" ref={register} />
            <SelectField
              id="insured_title"
              name={`${fieldName}[title]`}
              defaultValue={insuredsInfo?.title}
              inputRef={register(messages.requiredSelect)}
              error={errors.order?.insureds_attributes?.[index]?.title?.message}
              options={titleENOptions}
            />
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup label="เพศ" htmlForLabel="insured_gender" require>
            <SelectField
              id="insured_gender"
              name={`${fieldName}[gender]`}
              defaultValue={insuredsInfo?.gender}
              inputRef={register(messages.requiredSelect)}
              error={errors.order?.insureds_attributes?.[index]?.gender?.message}
              options={genderByTitle}
              disabled={watchInsuredTitle === ""}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <FormGroup
            label="ชื่อ"
            htmlForLabel="insured_firstname"
            errorRequire="(ระบุชื่อเป็นภาษาอังกฤษ)"
            require
          >
            <Controller
              id="insured_firstname"
              name={`${fieldName}[firstname]`}
              rules={messages.required}
              defaultValue={insuredsInfo?.firstname}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  value={value}
                  placeholder="ภาษาอังกฤษ"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (/^[A-Za-z]*$/.test(e.target.value)) onChange(e.target.value.toUpperCase());
                  }}
                  error={errors.order?.insureds_attributes?.[index]?.firstname?.message}
                />
              )}
            />
          </FormGroup>
        </div>

        <div className="col-6">
          <FormGroup
            label="นามสกุล"
            htmlForLabel="insured_lastname"
            errorRequire="(ระบุนามสกุลเป็นภาษาอังกฤษ)"
            require
          >
            <Controller
              id="insured_lastname"
              name={`${fieldName}[lastname]`}
              defaultValue={insuredsInfo?.lastname}
              rules={messages.required}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  value={value}
                  placeholder="ภาษาอังกฤษ"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (/^[A-Za-z]*$/.test(e.target.value)) onChange(e.target.value.toUpperCase());
                  }}
                  error={errors.order?.insureds_attributes?.[index]?.lastname?.message}
                />
              )}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <FormGroup
            label="วันเดือนปีเกิด"
            htmlForLabel="insured_birthday"
            require
            errorRequire={policyPeriodLabel}
          >
            <Controller
              name={`${fieldName}[birthday]`}
              rules={messages.required}
              defaultValue={insuredsInfo?.birthday}
              control={control}
              render={({ value, name }) => {
                return (
                  <DatePickerField
                    type="text"
                    name={name}
                    id="insured_birthday"
                    defaultView="decade"
                    maxDate={datePickerMaxDate}
                    minDate={datePickerMinDate}
                    // callbackvalue={callbackvalueBirthDate}
                    inputRef={register({
                      ...messages.required,
                      ...messages.validateBirthdayEqualNow,
                    })}
                    value={value}
                    error={errors.order?.insureds_attributes?.[index]?.birthday?.message}
                  />
                );
              }}
            />
          </FormGroup>
        </div>

        <div className="col-6">
          <FormGroup label="สัญชาติ" htmlForLabel="insured_nationality" require>
            <SelectField
              id="insured_nationality"
              name={`${fieldName}[nationality]`}
              inputRef={register(messages.requiredSelect)}
              error={errors.order?.insureds_attributes?.[index]?.nationality?.message}
              options={nationalityOptions}
              defaultValue={insuredsInfo?.nationality}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6">
          {watchInsuredNationality !== "expat" && (
            <FormGroup label="เลขที่บัตรประชาชน" htmlForLabel={`insured_idcard${index}`} require>
              <Controller
                id={`insured_idcard${index}`}
                name={`${fieldName}[idcard]`}
                defaultValue={insuredsInfo?.idcard}
                rules={messages.required}
                control={control}
                render={({ onChange, value, ...rest }) => (
                  <TextField
                    {...rest}
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (/^[0-9]*$/.test(e.target.value)) onChange(e.target.value);
                    }}
                    maxLength={13}
                    inputRef={register({
                      ...messages.required,
                      ...messages.validateIdCard,
                    })}
                    error={errors.order?.insureds_attributes?.[index]?.idcard?.message}
                  />
                )}
              />
            </FormGroup>
          )}
          {watchInsuredNationality === "expat" && (
            <FormGroup label="หมายเลขพาสปอร์ต" htmlForLabel={`insured_passport${index}`} require>
              <Controller
                id={`insured_passport${index}`}
                name={`${fieldName}[passport]`}
                defaultValue={insuredsInfo?.passport}
                rules={messages.required}
                control={control}
                render={({ onChange, value, ...rest }) => (
                  <TextField
                    {...rest}
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (/^[A-Za-z0-9]*$/.test(e.target.value))
                        onChange(e.target.value.toUpperCase());
                    }}
                    helpText="ตัวอย่าง A123456"
                    inputRef={register(messages.required)}
                    error={errors.order?.insureds_attributes?.[index]?.passport?.message}
                  />
                )}
              />
            </FormGroup>
          )}
        </div>

        <div className="col-6">
          <FormGroup label="เบอร์มือถือ" htmlForLabel="insured_phone_number" require>
            <Controller
              id="insured_phone_number"
              name={`${fieldName}[phone_number]`}
              defaultValue={insuredsInfo?.phone_number}
              rules={messages.required}
              control={control}
              render={({ onChange, value, ...rest }) => (
                <TextField
                  {...rest}
                  value={value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (/^[0-9]*$/.test(e.target.value)) onChange(e.target.value);
                  }}
                  error={errors.order?.insureds_attributes?.[index]?.phone_number?.message}
                  inputRef={register(messages.mobileNumber)}
                  maxLength={10}
                />
              )}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <FormGroup
            label="อีเมล"
            htmlForLabel="insured_email"
            errorRequire="(สำหรับส่งกรมธรรม์ ให้กับผู้เอาประกันภัย)"
            require
          >
            <Controller
              id="insured_email"
              name={`${fieldName}[email]`}
              defaultValue={insuredsInfo?.email}
              rules={{ ...messages.required, ...messages.email }}
              control={control}
              render={({ onChange, value, ...rest }) => (
                <TextField
                  {...rest}
                  value={value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (/^[a-zA-Z0-9-_@.]*$/.test(e.target.value)) onChange(e.target.value);
                  }}
                  inputRef={register(messages.email)}
                  error={errors.order?.insureds_attributes?.[index]?.email?.message}
                />
              )}
            />
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default InsureInfoForm;
