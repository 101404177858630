import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { format, addDays, addYears, addWeeks } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select, { SelectInstance } from "react-select";
import QueryString from "query-string";
import {
  Button,
  DatePickerField,
  FormGroup,
  SelectField,
  SelectGroupField,
} from "~src/components/form";
import ToolTip from "~src/components/tooltip";
import { travellerOptions } from "~src/helpers/options";
import { dateFormat, parseDate } from "~/src/helpers/dateTime";
import { messages } from "~src/helpers/messages";
import { useAppSelector, useAppDispatch } from "~src/store";
import {
  travelOneTimeCountriesState,
  travelOneTimePopularCountriesState,
  travelYearlyCountriesState,
  countriesState,
  travelTypeState,
  destinationsState,
  startDateState,
  endDateState,
  showExceedLimitBuyModalState,
  travelersState,
  setEndDate,
  setStartDate,
  setTravelType,
  setDestinations,
  setIsShowExceedLimitBuyModal,
  setTravelers,
  TravelType,
  TravelSearch,
  fetchCountriesAction,
  setTravelInfo,
} from "./redux";
import TabButton from "./components/TabButton";
import ExceedLimitBuy from "~src/components/modal/travel/ExceedLimitBuy";
import { get, valuesIn } from "lodash";

const TravelForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const countries = useAppSelector(countriesState);
  const travelers = useAppSelector(travelersState);
  const travelType = useAppSelector(travelTypeState);
  const destinations = useAppSelector(destinationsState);
  const startDate = useAppSelector(startDateState);
  const endDate = useAppSelector(endDateState);
  const travelOneTimePopularCountries = useAppSelector(travelOneTimePopularCountriesState);
  const travelOneTimeCountries = useAppSelector(travelOneTimeCountriesState);
  const travelYearlyCountries = useAppSelector(travelYearlyCountriesState);
  const isShowExceedLimitBuyModal = useAppSelector(showExceedLimitBuyModalState);
  const [selectedDestinations, setSelectedDestinations] = useState([]);

  const dispatch = useAppDispatch();

  const { register, errors, handleSubmit, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const tokenQueryString = get(QueryString.parse(location.search), "token", "") as string;

  // React.useEffect(() => {
  //   if (!tokenQueryString) history.push("/search");
  // }, []);

  React.useEffect(() => {
    if (countries.status === "idle") {
      dispatch(fetchCountriesAction());
    }
  }, [countries]);

  function handleChangeTravelType(type: TravelType) {
    const date = new Date();
    const strToTime = new Date(dateFormat(new Date()));
    const weekDays = dateFormat(addWeeks(date, 1));
    const yearDays = dateFormat(addYears(strToTime.setDate(strToTime.getDate() - 1), 1));

    let startDate = dateFormat(date);
    let endDate = weekDays;
    if (type === "yearly") endDate = yearDays;

    dispatch(setDestinations([]))
    dispatch(setTravelers(""));
    dispatch(setTravelType({ type }));
    dispatch(setStartDate(startDate));
    dispatch(setEndDate(endDate));
  }

  function handleChangeStartDate(value: string) {
    const strToTime = new Date(value || dateFormat(new Date()));
    strToTime.setDate(strToTime.getDate() - 1);

    let endDateTime = addWeeks(strToTime, 1);
    if (travelType === "yearly") endDateTime = addYears(strToTime, 1);

    const formatDate = dateFormat(endDateTime);

    dispatch(setStartDate(value));
    dispatch(setEndDate(formatDate));
  }

  function handleChangeEndDate(value: string) {
    dispatch(setEndDate(value));
  }

  function handleOnDestinationsChanged(destination) {
    setSelectedDestinations(destination);
  }

  function onSubmit(data: TravelSearch) {
    if (data.traveller === "n") {
      dispatch(setIsShowExceedLimitBuyModal(true));
      return;
    }

    data.token = tokenQueryString;

    if (travelType) data.policy_period = travelType;
    data.destinations = destinations.map(destination => destination.value);
    
    const queries = QueryString.stringify(data, {arrayFormat: 'bracket'});
    window.location.href = `/travels/search?${queries}`;
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-11 col-lg-11 col-xl-9">
          <div className="flex items-center justify-between">
            <h3 className="white-color">ค้นหาแผนประกันเดินทาง</h3>
          </div>
          <div className="travel-tab">
            <TabButton
              active={travelType === "one_time"}
              icon={<i className="icon-travel" />}
              label="รายครั้ง"
              onClick={() => handleChangeTravelType("one_time")}
            />
            <TabButton
              active={travelType === "yearly"}
              icon={<i className="icon-travel" />}
              label="รายปี"
              onClick={() => handleChangeTravelType("yearly")}
            />
          </div>
          <div className="travel-main-page-searchbox">
            {countries.status === "succeeded" && (
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="travel-main-page-searchbox-fields">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-4">
                      <div className="flex items-center">
                        <p className="mb-1 mr-1 text-sm text-grayCustom-1">ประเทศปลายทาง</p>
                        <ToolTip text="กรณีขอวีซ่าเดินทางไปกลุ่มประเทศ Schengen(เชงเก้น) เลือกประเทศปลายทาง Schengen และลูกค้าสามารถเลือกประเทศปลายทางได้สูงสุด 10 ประเทศ" />
                      </div>
                      <input
                        type="hidden"
                        name="destinations"
                        ref={register(messages.required)}
                        value={destinations}
                      />
                      <Select
                        isMulti
                        options={travelType === "one_time" ? 
                          (
                            [
                              { label: "ประเทศยอดนิยม", options: travelOneTimePopularCountries },
                              { label: "ประเทศทั้งหมด", options: travelOneTimeCountries },
                            ]
                          ) : (
                            travelYearlyCountries
                          )
                        }
                        isOptionDisabled={() => destinations.length >= 10}
                        onChange={(val) => {
                          dispatch(setDestinations(val))
                        }}
                        value={destinations}
                      />
                    </div>
                    <div className="col-12 col-md-2">
                      <FormGroup label="จำนวนผู้เดินทาง">
                        <SelectField
                          name="traveller"
                          id="traveller"
                          onChange={(e) => dispatch(setTravelers(e.target.value))}
                          value={travelers}
                          options={travellerOptions}
                          inputRef={register(messages.required)}
                          error={!!errors.traveller && errors.traveller.message}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12 col-md-3">
                      <FormGroup label="ออกจากไทย">
                        <DatePickerField
                          type="text"
                          name="from_date"
                          id="from_date"
                          defaultView="month"
                          minDate={parseDate(format(new Date(), "yyyy-MM-dd"))}
                          maxDate={parseDate(dateFormat(addDays(new Date(), 179)))}
                          value={startDate}
                          onChange={handleChangeStartDate}
                          callbackvalue={handleChangeStartDate}
                          inputRef={register(messages.required)}
                          error={!!errors.from_date && errors.from_date.message}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12 col-md-3">
                      <FormGroup label="กลับถึงไทย">
                        <DatePickerField
                          type="text"
                          name="to_date"
                          id="to_date"
                          defaultView="month"
                          minDate={parseDate(format(new Date(startDate), "yyyy-MM-dd"))}
                          maxDate={parseDate(dateFormat(addDays(new Date(startDate), 179)))}
                          value={endDate}
                          onChange={handleChangeEndDate}
                          readOnly={travelType === "yearly"}
                          callbackvalue={handleChangeEndDate}
                          inputRef={register(messages.required)}
                          error={!!errors.to_date && errors.to_date.message}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="flex justify-center mt-4 text-center">
                    <FormGroup>
                      <Button type="submit" color="secondary" classNameOption="w-44">
                        ค้นหา
                      </Button>
                    </FormGroup>
                  </div>

                  <div className=" text-grayCustom-1">
                    <p>หมายเหตุ:</p>
                    <ol className="list-disc ml-4">
                      <li>
                        ผู้ซื้อต้องมีถิ่นฐานอยู่ในประเทศ ต้องเริ่มเดินทางจากประเทศไทย
                        และสิ้นสุดที่ประเทศไทยเท่านั้น
                      </li>
                      <li>ซื้อล่วงหน้าได้ 2 ชั่วโมงก่อนเดินทาง</li>
                      <li>สามารถเลือกปลายทางได้สูงสุด 10 ประเทศ</li>
                    </ol>
                  </div>
                </div>
              </form>
            )}

            {countries.status === "failed" && (
              <div>
                <p className="text-center text-lg">Sorry. There is something wrong.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ExceedLimitBuy
        isOpen={isShowExceedLimitBuyModal}
        onClose={() => dispatch(setIsShowExceedLimitBuyModal(false))}
      />
    </>
  );
};

export default TravelForm;
