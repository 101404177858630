import { createSlice, createAsyncThunk, PayloadAction, AsyncThunkOptions } from "@reduxjs/toolkit";
import { filter } from "lodash";
import { dateFormat } from "~src/helpers";
import { addWeeks } from "date-fns";
import { RootState } from "~src/store";
import {
  CountryResponse,
  TravelProductState,
  TravelType,
  CreateQuotationPayload,
  PlaceOrderParam,
} from "./types";
import services from "../services";
import { TravelProductDto } from "~src/types/travel";
import {
  fetchCountriesAction,
  fetchCreateQuotationAction,
  fetchGenerateSecureTokenAction,
  fetchPlaceOrderAction,
  fetchProductByIdAction,
  fetchTravelProductsAction,
} from "./actions";

const initialState: TravelProductState = {
  travelType: "one_time",
  destinations: [],
  travelers: "",
  startDate: dateFormat(new Date()),
  endDate: dateFormat(addWeeks(new Date(), 1)),
  isShowExceedLimitBuyModal: false,
  token: "",
  secureToken: {
    status: "idle",
    token: "",
  },
  countries: {
    status: "idle",
    all: [],
    popularCountries: [],
  },
  products: {
    status: "idle",
    countries: [],
    data: [],
  },
  product: {
    status: "idle",
    data: null,
  },
  coverageModal: {
    open: false,
    data: null,
  },
  quotation: {
    status: "idle",
    data: null,
  },
  placeOrder: {
    status: "idle",
    data: {
      order_id: 0,
    },
  },
};

const travelSlice = createSlice({
  name: "trvelProduct",
  initialState,
  reducers: {
    setTravelType: (state, { payload }: PayloadAction<{ type: TravelType }>) => {
      return {
        ...state,
        travelType: payload.type,
      };
    },
    setDestinations: (state, { payload }: PayloadAction<string[]>) => {
      return {
        ...state,
        destinations: payload,
      }
    },
    setTravelers: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        travelers: payload,
      };
    },
    setStartDate: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        startDate: payload,
      };
    },
    setEndDate: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        endDate: payload,
      };
    },
    setIsShowExceedLimitBuyModal: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowExceedLimitBuyModal: payload,
      };
    },
    setTravelInfo: (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        travelers: payload.traveller,
        startDate: payload.from_date,
        endDate: payload.to_date,
        destinations: payload.destinations,
        travelType: payload.policy_period,
        token: payload.token,
      };
    },
    openCoverageModal: (state, { payload }: PayloadAction<TravelProductDto>) => {
      return {
        ...state,
        coverageModal: {
          open: true,
          data: payload,
        },
      };
    },
    closeCoverageModal: (state) => {
      return {
        ...state,
        coverageModal: {
          open: false,
          data: null,
        },
        product: {
          status: "idle",
          data: null,
        },
      };
    },
    resetProducts: (state) => {
      return {
        ...state,
        products: {
          status: "idle",
          countries: [],
          data: [],
        },
      };
    },
    resetCreateQuotationAndPlaceOrder: (state) => {
      return {
        ...state,
        quotation: {
          status: "idle",
          data: null,
        },
        placeOrder: {
          status: "idle",
          data: {
            order_id: 0,
          },
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGenerateSecureTokenAction.pending, (state) => {
        return {
          ...state,
          secureToken: {
            status: "loading",
            token: "",
          },
        };
      })
      .addCase(fetchGenerateSecureTokenAction.fulfilled, (state, { payload }) => {
        return {
          ...state,
          secureToken: {
            status: "succeeded",
            token: payload.token,
          },
        };
      });

    builder
      .addCase(fetchCountriesAction.pending, (state) => {
        return {
          ...state,
          countries: {
            ...state.countries,
            status: "loading",
          },
        };
      })
      .addCase(
        fetchCountriesAction.fulfilled,
        (state, { payload }: PayloadAction<CountryResponse>) => {
          return {
            ...state,
            countries: {
              ...state.countries,
              status: "succeeded",
              all: payload.content.countries,
              popularCountries: payload.content.popular_countries,
            },
          };
        }
      )
      .addCase(fetchCountriesAction.rejected, (state) => {
        return {
          ...state,
          countries: {
            ...state.countries,
            status: "failed",
          },
        };
      });

    builder
      .addCase(fetchTravelProductsAction.pending, (state) => {
        return {
          ...state,
          products: {
            status: "loading",
            countries: [],
            data: [],
          },
        };
      })
      .addCase(fetchTravelProductsAction.fulfilled, (state, { payload }) => {
        return {
          ...state,
          products: {
            status: "succeeded",
            countries: payload.countries,
            data: payload.packages,
          },
        };
      })
      .addCase(fetchTravelProductsAction.rejected, (state) => {
        return {
          ...state,
          products: {
            status: "failed",
            countries: [],
            data: [],
          },
        };
      });

    builder
      .addCase(fetchCreateQuotationAction.pending, (state) => {
        return {
          ...state,
          quotation: {
            status: "loading",
            data: null,
          },
        };
      })
      .addCase(fetchCreateQuotationAction.fulfilled, (state, { payload }) => {
        return {
          ...state,
          quotation: {
            status: "succeeded",
            data: payload.content,
          },
        };
      });

    builder
      .addCase(fetchPlaceOrderAction.pending, (state) => {
        return {
          ...state,
          placeOrder: {
            status: "loading",
            data: {
              order_id: 0,
            },
          },
        };
      })
      .addCase(fetchPlaceOrderAction.fulfilled, (state, { payload }) => {
        console.log(payload);
        return {
          ...state,
          placeOrder: {
            status: "succeeded",
            data: {
              order_id: Number(payload.content.order_id),
            },
          },
        };
      });

    builder
      .addCase(fetchProductByIdAction.pending, (state) => {
        return {
          ...state,
          product: {
            status: "loading",
            data: null,
          },
        };
      })
      .addCase(fetchProductByIdAction.fulfilled, (state, { payload }) => {
        return {
          ...state,
          product: {
            status: "succeeded",
            data: payload,
          },
        };
      });
  },
});

export const secureTokenState = (state: RootState) => {
  return state.travel.secureToken;
};

export const countriesState = (state: RootState) => {
  return state.travel.countries;
};

export const travelersState = (state: RootState) => {
  return state.travel.travelers;
};

export const travelTypeState = (state: RootState): TravelType => {
  return state.travel.travelType;
};

export const destinationsState = (state: RootState) => {
  return state.travel.destinations;
}

export const startDateState = (state: RootState) => {
  return state.travel.startDate;
};

export const endDateState = (state: RootState) => {
  return state.travel.endDate;
};

export const worldwideCountriesState = (state: RootState) => {
  return filter(state.travel.countries.popularCountries, ["label", "WORLDWIDE"]);
};

export const travelOneTimeCountriesState = (state: RootState) => {
  return filter(state.travel.countries.all, ["travel_type", "one_time"]);
}

export const travelOneTimePopularCountriesState = (state: RootState) => {
  return filter(state.travel.countries.popularCountries, ["travel_type", "one_time"]);
}

export const travelYearlyCountriesState = (state: RootState) => {
  return filter(state.travel.countries.popularCountries, ["travel_type", "yearly"]);
}

export const showExceedLimitBuyModalState = (state: RootState) => {
  return state.travel.isShowExceedLimitBuyModal;
};

export const travelInfoState = (state: RootState) => {
  return {
    travelers: state.travel.travelers,
    startDate: state.travel.startDate,
    endDate: state.travel.endDate,
    travelType: state.travel.travelType,
    destinations: state.travel.destinations,
    count: state.travel.products.data.length || 0,
    token: state.travel.token,
  };
};

export const travelProductsState = (state: RootState) => {
  return state.travel.products;
};

export const travelProductsStatus = (state: RootState) => {
  return state.travel.products.status;
};

export const getCountryNameState = (state: RootState) => {
  return state.travel.products.countries.map(c => c.name).join(', ');
};

export const travelProductsData = (state: RootState) => {
  let data = [];
  state.travel.products.data.forEach((element) => {
    data.push({ ...element, standard_price: parseFloat(element.standard_price.toString()) });
  });
  return data.sort((a, b) => a.standard_price - b.standard_price);
};

export const travelProductState = (state: RootState) => {
  return state.travel.product;
};

export const coverageModalState = (state: RootState) => {
  return state.travel.coverageModal;
};

export const quotationState = (state: RootState) => {
  return state.travel.quotation;
};

export const placeOrderState = (state: RootState) => {
  return state.travel.placeOrder;
};

export const {
  setTravelType,
  setDestinations,
  setTravelers,
  setStartDate,
  setEndDate,
  setIsShowExceedLimitBuyModal,
  setTravelInfo,
  openCoverageModal,
  closeCoverageModal,
  resetCreateQuotationAndPlaceOrder,
  resetProducts,
} = travelSlice.actions;

export default travelSlice.reducer;
