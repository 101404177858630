import React from "react";
import CoverageList from "~src/components/card/CoverageList";
import InsurerDetail from "~src/components/card/InsurerDetail";
import InsurerImg from "~src/components/card/InsurerImg";
import Price from "~src/components/card/Price";
import { useAppDispatch, useAppSelector } from "~src/store";
import {
  CreateQuotationPayload,
  fetchCreateQuotationAction,
  openCoverageModal,
  travelersState,
  travelInfoState,
  TravelProductResponse,
} from "../redux";

interface Props {
  data: TravelProductResponse;
  coveragePdfUrl?: string;
  remark?: string;
}

const ProductCard: React.FC<Props> = ({ data, coveragePdfUrl, remark }) => {
  const traveller = useAppSelector(travelersState);
  const travelInfo = useAppSelector(travelInfoState);
  const coverages = data.coverages;
  const coverageDetails = [
    { ชดเชยกรณีเสียชีวิต: coverages.dismemberment_coverage },
    { ค่ารักษาพยาบาล: coverages.overseas_medical_expenses_coverage },
    {
      เคลื่อนย้ายฉุกเฉิน: coverages.emergency_evacuation_and_repatriation_coverage,
    },
    { "สัมภาระสูญหาย/เสียหาย": coverages.loss_or_damage_baggage_coverage },
    {
      การบอกเลิกการเดินทาง: coverages.trip_cancellation_postponement_coverage,
    },
  ];

  const dispatch = useAppDispatch();

  const handleCreateQuotation = (product: TravelProductResponse) => {
    const param: CreateQuotationPayload = {
      token: travelInfo.token,
      product_ids: product.id,
      policy_type: product.insurer_class,
      customer_phone: null,
      customer_name: "self buy from insured",
      product_type: "travel",
      criteria: {
        traveller: travelInfo.travelers,
        destinations: travelInfo.destinations,
        from_date: travelInfo.startDate,
        to_date: travelInfo.endDate,
        token: travelInfo.token,
      },
      quotes: [
        {
          product_id: product.id,
          discount: 0,
        },
      ],
    };
    dispatch(fetchCreateQuotationAction(param));
  };

  return (
    <div className="shadow-md bg-white p-4 rounded-5 mt-4 transition duration-500 ease-in-out hover:scale-105">
      <div id="card-header" className="flex flex-col gap-2">
        <div className="flex flex-row gap-3">
          <InsurerImg
            style={{ width: "60px", height: "60px" }}
            src={data.insurer_img}
            alt={data.insurer_name}
          />
          <InsurerDetail name={data.insurer_name} productName={data.product_name} />
        </div>
        <div className="flex flex-row justify-between">
          <Price
            className="font-extrabold text-lg"
            value={data.standard_price * Number(traveller)}
            suffix=""
          />
        </div>
        <hr className="mt-2" />
        {remark && (
          <div className="flex justify-center">
            <div className="px-4 py-1 border border-secondary rounded-20">{remark}</div>
          </div>
        )}
        <div id="card-body">
          {coverageDetails.map((o, i) => (
            <CoverageList label={Object.keys(o)} value={Object.values(o)} key={i} type={null} />
          ))}
        </div>
        <div id="card-footer" className="flex flex-row mt-3 -mx-4 -mb-4">
          <>
            {coveragePdfUrl ? (
              <a
                href={coveragePdfUrl}
                target="_blank"
                className="rounded-bl-5 flex-1 cursor-pointer bg-gray-100 text-primary p-3 text-center"
              >
                ดาวน์โหลด
              </a>
            ) : (
              <button
                type="button"
                onClick={() => dispatch(openCoverageModal({ ...data }))}
                className="rounded-bl-5 flex-1 cursor-pointer bg-gray-100 text-primary p-3"
              >
                ดูรายละเอียด
              </button>
            )}

            <button
              type="button"
              onClick={() => handleCreateQuotation(data)}
              className="rounded-br-5 flex-1 cursor-pointer bg-primary text-white p-3 flex flex-row justify-center"
            >
              <i className="material-icons mr-1 text-base">add_shopping_cart</i>
              ซื้อเลย
            </button>
          </>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
